/* eslint-disable no-undef */
import { faBath } from '@fortawesome/pro-light-svg-icons/faBath';
import { faBedAlt } from '@fortawesome/pro-light-svg-icons/faBedAlt';
import { faCouch } from '@fortawesome/pro-light-svg-icons/faCouch';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faHomeLgAlt } from '@fortawesome/pro-regular-svg-icons/faHomeLgAlt';
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import { Property } from '@propertypal/shared/src/types/property';
import getOpeningTimes from '@propertypal/shared/src/utils/agent/getOpeningTimes';
import getContact, { EnquiryContactDetails } from '@propertypal/shared/src/utils/enquiries/getContact';
import getSellingAgent, { getAgentColours } from '@propertypal/shared/src/utils/property/getSellingAgent';
import puralise from '@propertypal/shared/src/utils/puralise';
import Link from 'next/link';
import React, { CSSProperties, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import Button from '../buttons/Button';
import HorizontalDivider from '../divider/HorizontalDivider';
import Chevron from '../icons/Chevron';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { RowAlignCenter } from '../layout';
import useMediaQuery from '../media-query/useMediaQuery';
import { Heading, Text, LinkText } from '../typography';
import OpenViewingOverlay from './OpenViewingOverlay';
import PropertyFavourite from './PropertyFavourite';
import PropertyPrice from './PropertyPrice';
import {
  Container,
  KeyDetails,
  SummaryItem,
  Title,
  AgentBox,
  AgentLogo,
  AgentText,
  CallAgentBox,
  ShowPhoneNumber,
  AgentBoxFixed,
  AgentBoxFixedButtons,
  LicenceWarning,
  NameAndPSRTextWrapper,
  SummaryBox,
  AgentBoxHeader,
  AgentBoxContent,
  AgentExtraInfo,
  AgentListItem,
  IconBox,
  ShowMoreDetails,
  AgentButtons,
} from './PropertySummary.style';
import ShowHomesBox from './ShowHomesBox';

interface Props {
  containerStyle?: CSSProperties;
  property: Property;
  onCallClick: (location: string) => void;
  onShowNumberClick: (location: string) => void;
  onEnquiryClick: () => void;
  onRegisterInterestClick: () => void;
  onRequestValuationClick: () => void;
  registerInterest?: boolean;
  requestValuation?: boolean;
  favourited: boolean;
  toggleFavourited: (favourited: boolean) => Promise<boolean>;
  leadId: number;
}

const PhoneNumberButton = (
  props: Pick<Props, 'containerStyle' | 'onCallClick' | 'onShowNumberClick'> & {
    color?: string;
    fontColor?: string;
    location: string;
    useShortText: boolean;
  },
) => {
  return (
    <>
      <ShowPhoneNumber data-testid="showPhoneNumber">
        <Button
          containerStyle={props.containerStyle}
          color={props.color}
          fontColor={props.fontColor}
          onClick={() => props.onShowNumberClick(props.location)}
          testID="showPhoneNumberButton"
          type="button"
        >
          {props.useShortText ? 'Call' : 'Call the Agent'}
        </Button>
      </ShowPhoneNumber>

      <CallAgentBox>
        <Button
          containerStyle={props.containerStyle}
          onClick={() => props.onCallClick(props.location)}
          testID="callAgentButton"
          type="button"
          color={props.color}
          fontColor={props.fontColor}
        >
          {props.useShortText ? 'Call' : 'Call the Agent'}
        </Button>
      </CallAgentBox>
    </>
  );
};

const getTotalButtons = (props: Props, contactInfo: EnquiryContactDetails) => {
  let total = 0;

  if (contactInfo.email) total += 1;
  if (contactInfo.phone) total += 1;
  if (props.registerInterest) total += 1;
  if (props.requestValuation) total += 1;

  return total;
};

const PropertySummary: FunctionComponent<Props> = (props) => {
  const { property } = props;
  const theme = useTheme();
  const sellingAgent = getSellingAgent(property);
  const {
    pdpBrandingBgColour,
    pdpBrandingBorderColour,
    pdpBrandingButtonBgColour,
    pdpBrandingButtonTextColour,
    pdpBrandingLinkColour,
  } = getAgentColours(sellingAgent);
  const [showMore, setShowMore] = useState(false);
  // const agentAward = sellingAgent?.propertypalAwards?.length ? sellingAgent?.propertypalAwards[0] : null;
  const timeLabels = getOpeningTimes(sellingAgent?.openingTimes || []);
  const contactBox = useRef<HTMLDivElement | null>(null);
  const keyDetails = useRef<HTMLDivElement>(null);
  const [showEnquiryBar, setShowEnquiryBar] = useState(false);
  const contactInfo = getContact(property);
  const page = property.type === 'development' ? 'DDP' : 'PDP';
  const leadsLink = property.leadIds?.length
    ? `/my/enquiries?leadId=${property.leadIds[0]}`
    : props.leadId
    ? `/my/enquiries?leadId=${props.leadId}`
    : '';
  const showKeyDetails =
    property.numBedrooms || property.numBathrooms || property.numReceptionRooms || property.epc || property.ber;
  const totalButtons = getTotalButtons(props, contactInfo);
  const isMobile = useMediaQuery({ query: `(${tabletMax})` });
  const useShortText = isMobile && totalButtons >= 4;

  // only add comma add end of address if address is more than 1 word
  // particularly will find this for ROI properties
  const addressAppend = () => {
    // handles potentially hyphenated addresses
    const words = props.property.displayAddressLine1.replace('-', '').match(/(\w+)/g);
    if (words && words.length > 1) return ',';
    return null;
  };

  const enquireNowClick = (location: string) => {
    trackGaEvent('enquiry_click', { page, location });
    props.onEnquiryClick();
  };

  const registerInterestClick = (location: string) => {
    trackGaEvent('register_interest_click', { page, location });
    props.onRegisterInterestClick();
  };

  const requestValuationClick = () => {
    trackGaEvent('request_valuation_click', { page, location: 'Enquiry Box' });
    props.onRequestValuationClick();
  };

  useEffect(() => {
    const checkBelowThreshold: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        setShowEnquiryBar(entry.boundingClientRect.top < 0);
      });
    };

    const observer = new IntersectionObserver(checkBelowThreshold, {
      threshold: 1,
    });

    if (contactBox.current) {
      observer.observe(contactBox.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Container className="pp-property-summary">
        <SummaryBox>
          {props.property.openViewing && (
            <OpenViewingOverlay
              containerStyle={{ marginRight: 10, marginBottom: 15 }}
              openViewing={props.property.openViewing}
              themeName="light"
            />
          )}

          <Heading fontWeight="600" data-testid="address-line-1" style={{ position: 'relative', paddingRight: 45 }}>
            {props.property.type === 'property' && (
              <PropertyFavourite
                containerStyle={{ position: 'absolute', top: 0, right: 0, zIndex: 10 }}
                favourited={props.favourited}
                onFavourite={props.toggleFavourited}
              />
            )}

            {props.property.displayAddressLine1}
            {addressAppend()}
          </Heading>

          <Text fontSize={24} color={theme.textLight} data-testid="address-line-2">
            {props.property.displayAddressLine2}
          </Text>

          <Text fontSize={18} mt={10} color={theme.textLight} data-testid="property-brief-text">
            {props.property.briefText}
          </Text>

          {props.property.development && (
            <Text fontSize={16} mt={15} color={theme.primary} className="pp-development-link-text">
              <Link href={props.property.development.path} style={{ textDecoration: 'underline' }}>
                This property forms part of the <b>{props.property.development.name}</b> development{' '}
                <Chevron size={9} />
              </Link>
            </Text>
          )}

          <PropertyPrice
            fontSize={24}
            price={props.property.price}
            alternativeCurrencyPrice={props.property.alternativeCurrencyPrice}
            containerStyle={{ marginTop: 40 }}
            status={props.property.status}
            comingSoonText={props.property.comingSoonText}
            statusButton
          />

          {showKeyDetails && (
            <KeyDetails ref={keyDetails}>
              {!!property.numBedrooms && (
                <SummaryItem>
                  <FontAwesomeIcon
                    icon={faBedAlt}
                    color={theme.backgroundMid}
                    className="pp-summary-icon pp-summary-icon-beds"
                  />

                  <Text ml={10}>
                    {property.numBedrooms} {puralise('Bedroom', 'Bedrooms', property.numBedrooms)}
                  </Text>
                </SummaryItem>
              )}

              {!!property.numBathrooms && (
                <SummaryItem>
                  <FontAwesomeIcon
                    icon={faBath}
                    color={theme.backgroundMid}
                    className="pp-summary-icon pp-summary-icon-baths"
                  />

                  <Text ml={10}>
                    {property.numBathrooms} {puralise('Bathroom', 'Bathrooms', property.numBathrooms)}
                  </Text>
                </SummaryItem>
              )}

              {!!property.numReceptionRooms && (
                <SummaryItem>
                  <FontAwesomeIcon
                    icon={faCouch}
                    color={theme.backgroundMid}
                    className="pp-summary-icon pp-summary-icon-receptions"
                  />

                  <Text ml={10}>
                    {property.numReceptionRooms} {puralise('Reception', 'Receptions', property.numReceptionRooms)}
                  </Text>
                </SummaryItem>
              )}
            </KeyDetails>
          )}

          <ShowHomesBox property={props.property} containerStyle={{ marginTop: 30 }} />

          {/* {(property.type === 'development' || property.development) && (
            <AgentAwards awards={property.account.propertypalAwards} containerStyle={{ marginTop: 30 }} />
          )} */}
        </SummaryBox>

        <AgentBox className="pp-contact-agent" color={pdpBrandingBorderColour}>
          {sellingAgent && (
            <>
              <AgentBoxHeader color={pdpBrandingBgColour}>
                <Link href={sellingAgent.landingPageUrl} prefetch={false}>
                  {sellingAgent.logo && sellingAgent.logo.standard && (
                    <AgentLogo data-testid="agent-logo" src={sellingAgent.logo.standard} alt="Agent Logo" />
                  )}
                </Link>
              </AgentBoxHeader>

              <Title>Contact {sellingAgent.organisation}</Title>
            </>
          )}

          {!sellingAgent && <Title>Marketed by multiple agents</Title>}

          {sellingAgent && sellingAgent.psrLicenceNumber && property.countryCode === 'IRL' && (
            <AgentText>
              PSR Licence: {sellingAgent.psrLicenceNumber}
              {sellingAgent.psrLicenceNumber === 'NO LICENCE' && (
                <LicenceWarning href="/psr-licence-number" prefetch={false}>
                  ?
                </LicenceWarning>
              )}
            </AgentText>
          )}

          <Text className="show-print" align="center" fontSize={18} mb={15}>
            {sellingAgent?.phoneNumber?.international}
          </Text>

          {!!property.leadIds?.length && (
            <Text
              align="center"
              color={theme.primary}
              fontSize={14}
              fontWeight="600"
              style={{ maxWidth: 240, margin: '0 auto' }}
            >
              <FontAwesomeIcon icon={faExclamationCircle} style={{ marginRight: 8 }} />
              You&apos;ve already made an enquiry about this property.{' '}
              <Link href={leadsLink} style={{ textDecoration: 'underline' }}>
                View <Chevron size={10} />
              </Link>
            </Text>
          )}

          <AgentBoxContent className="hide-print">
            <AgentButtons>
              {/* {sellingAgent?.enhancedBranding && agentAward && (
                <Link href={sellingAgent.landingPageUrl} prefetch={false}>
                  <AwardLogo src={agentAward.imageUrl} alt={agentAward.label} data-testid="award-logo" />
                </Link>
              )} */}

              {property.published && (
                <>
                  {props.registerInterest && !leadsLink && (
                    <>
                      <Button
                        containerStyle={{ height: 50, marginBottom: 15, maxWidth: 400 }}
                        onClick={() => registerInterestClick('Enquiry Box')}
                        testID="registerInterestButton"
                        type="button"
                        className="pp-register-interest"
                        color={pdpBrandingButtonBgColour}
                        fontColor={pdpBrandingButtonTextColour}
                      >
                        Register Your Interest
                      </Button>

                      <HorizontalDivider
                        color={theme.textDark}
                        dividerColor={theme.backgroundLight}
                        text="or"
                        mb={15}
                      />
                    </>
                  )}

                  {!!leadsLink && (
                    <Link href={leadsLink} style={{ width: '100%', maxWidth: 400, marginBottom: 15 }}>
                      <Button
                        containerStyle={{ height: 50 }}
                        type="button"
                        color={pdpBrandingButtonBgColour}
                        fontColor={pdpBrandingButtonTextColour}
                      >
                        Send another message
                      </Button>
                    </Link>
                  )}

                  {contactInfo.email && !leadsLink && (
                    <Button
                      containerStyle={{ height: 50, marginBottom: 15, maxWidth: 400 }}
                      onClick={() => enquireNowClick('Enquiry Box')}
                      testID="enquireNowButton"
                      type="button"
                      color={pdpBrandingButtonBgColour}
                      fontColor={pdpBrandingButtonTextColour}
                    >
                      {props.registerInterest ? 'Email The Agent' : 'Enquire Now'}
                    </Button>
                  )}

                  {contactInfo.phone && (
                    <PhoneNumberButton
                      {...props}
                      color={pdpBrandingButtonBgColour}
                      fontColor={pdpBrandingButtonTextColour}
                      containerStyle={{ height: 50, margin: '0 auto 15px', maxWidth: 400 }}
                      location="Enquiry Box"
                      useShortText={false}
                    />
                  )}

                  {props.requestValuation && (
                    <>
                      <HorizontalDivider
                        color={theme.textDark}
                        dividerColor={theme.backgroundLight}
                        text="or"
                        mb={15}
                      />

                      <Button
                        containerStyle={{ height: 50, marginBottom: 15, maxWidth: 400 }}
                        onClick={requestValuationClick}
                        testID="requestValuationButton"
                        type="button"
                        color={pdpBrandingButtonBgColour}
                        fontColor={pdpBrandingButtonTextColour}
                      >
                        Request a Valuation
                      </Button>
                    </>
                  )}
                </>
              )}
            </AgentButtons>

            {sellingAgent?.enhancedBranding && (
              <AgentExtraInfo show={showMore}>
                <AgentListItem>
                  <IconBox>
                    <FontAwesomeIcon icon={faMapMarkerAlt} color={theme.textDark} style={{ fontSize: 18 }} />
                  </IconBox>

                  <Text>
                    {sellingAgent.displayAddress}
                    <br />
                    {sellingAgent.groupLandingPageUrl && (
                      <Link href={sellingAgent.groupLandingPageUrl}>
                        <LinkText as="span" fontSize={14} color={pdpBrandingLinkColour}>
                          View our other branches <Chevron size={11} />
                        </LinkText>
                      </Link>
                    )}
                  </Text>
                </AgentListItem>

                {!!timeLabels.length && (
                  <AgentListItem>
                    <IconBox>
                      <FontAwesomeIcon icon={faClock} color={theme.textDark} style={{ fontSize: 18 }} />
                    </IconBox>

                    <div>
                      {timeLabels.map((timeLabel) => (
                        <Text key={timeLabel.firstDay}>
                          {timeLabel.firstDay}
                          {timeLabel.lastDay && ` to ${timeLabel.lastDay}`}: {timeLabel.openingTime}
                          {timeLabel.closingTime && `- ${timeLabel.closingTime}`}
                        </Text>
                      ))}
                    </div>
                  </AgentListItem>
                )}

                {sellingAgent.searchUrl && (
                  <AgentListItem>
                    <IconBox>
                      <FontAwesomeIcon icon={faHomeLgAlt} color={theme.textDark} style={{ fontSize: 18 }} />
                    </IconBox>

                    <Text>
                      <Link href={sellingAgent.searchUrl} prefetch={false}>
                        <LinkText as="span" fontSize={14} color={pdpBrandingLinkColour}>
                          View our similar properties <Chevron size={11} />
                        </LinkText>
                      </Link>
                    </Text>
                  </AgentListItem>
                )}

                {/* {agentAward && sellingAgent.landingPageUrl && (
                  <AgentListItem>
                    <IconBox>
                      <FontAwesomeIcon icon={faTrophy} color={theme.textDark} style={{ fontSize: 18 }} />
                    </IconBox>

                    <Link href={sellingAgent.landingPageUrl} prefetch={false}>
                      <AwardLogo src={agentAward.imageUrl} alt={agentAward.label} data-testid="award-logo" />
                    </Link>
                  </AgentListItem>
                )} */}
              </AgentExtraInfo>
            )}
          </AgentBoxContent>

          {sellingAgent?.enhancedBranding && (
            <ShowMoreDetails
              color={pdpBrandingLinkColour}
              onClick={() => setShowMore(true)}
              show={!showMore}
              className="hide-print"
            >
              Show more details <FontAwesomeIcon icon={faChevronDown} color={pdpBrandingLinkColour} />
            </ShowMoreDetails>
          )}
        </AgentBox>

        <AgentBoxFixed
          className="pp-contact-agent hide-print"
          style={{ top: showEnquiryBar ? 0 : -60, visibility: showEnquiryBar ? 'visible' : 'hidden' }}
        >
          {sellingAgent && (
            <RowAlignCenter>
              {sellingAgent.logo && sellingAgent.logo.standard && (
                <img
                  src={sellingAgent.logo.standard}
                  alt={`${property.development ? 'Developer' : 'Agent'} Logo`}
                  style={{ marginRight: 15 }}
                  data-testid="agent-logo-fixed"
                />
              )}
              <NameAndPSRTextWrapper>
                <AgentText>{sellingAgent.organisation}</AgentText>
                {sellingAgent.psrLicenceNumber && property.countryCode === 'IRL' && (
                  <AgentText>
                    PSR Licence: {sellingAgent.psrLicenceNumber}
                    {sellingAgent.psrLicenceNumber === 'NO LICENCE' && (
                      <LicenceWarning href="/psr-licence-number" prefetch={false} style={{ textAlign: 'center' }}>
                        ?
                      </LicenceWarning>
                    )}
                  </AgentText>
                )}
              </NameAndPSRTextWrapper>
            </RowAlignCenter>
          )}

          {!sellingAgent && (
            <RowAlignCenter>
              <AgentText>Marketed by multiple agents</AgentText>
            </RowAlignCenter>
          )}

          {property.published && (
            <AgentBoxFixedButtons numBtns={totalButtons}>
              {props.requestValuation && (
                <Button
                  containerStyle={{ height: 50, marginRight: 10 }}
                  onClick={requestValuationClick}
                  testID="requestValuationButton"
                  type="button"
                  color={pdpBrandingButtonBgColour}
                  fontColor={pdpBrandingButtonTextColour}
                >
                  {useShortText ? 'Valuation' : 'Request a Valuation'}
                </Button>
              )}

              {props.registerInterest && !leadsLink && (
                <Button
                  containerStyle={{ height: 50, marginRight: 10 }}
                  onClick={() => registerInterestClick('Sticky Bar')}
                  testID="registerInterestFixed"
                  type="button"
                  className="pp-register-interest"
                >
                  {useShortText ? 'Register' : 'Register Your Interest'}
                </Button>
              )}

              {leadsLink && (
                <Link href={leadsLink} style={{ width: '100%', marginRight: 10 }}>
                  <Button containerStyle={{ height: 50 }} type="button">
                    {useShortText ? 'Message' : 'Send another message'}
                  </Button>
                </Link>
              )}

              {contactInfo.email && !leadsLink && (
                <Button
                  containerStyle={{ height: 50, marginRight: 10 }}
                  onClick={() => enquireNowClick('Sticky Bar')}
                  testID="enquireNowFixed"
                  type="button"
                  color={pdpBrandingButtonBgColour}
                  fontColor={pdpBrandingButtonTextColour}
                >
                  {useShortText ? 'Email' : props.registerInterest ? 'Email The Agent' : 'Enquire Now'}
                </Button>
              )}

              {contactInfo.phone && (
                <PhoneNumberButton
                  {...props}
                  containerStyle={{ height: 50, marginRight: 10 }}
                  location="Sticky Bar"
                  color={pdpBrandingButtonBgColour}
                  fontColor={pdpBrandingButtonTextColour}
                  useShortText={useShortText}
                />
              )}
            </AgentBoxFixedButtons>
          )}
        </AgentBoxFixed>
      </Container>

      <div ref={contactBox} />
    </>
  );
};

export default PropertySummary;
