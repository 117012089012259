import { laptopMediumMax } from '@propertypal/shared/src/constants/mediaQueries';
import useApi from '@propertypal/shared/src/hooks/useApi';
import useEventListener from '@propertypal/shared/src/hooks/useEventListener';
import useLocalStorage from '@propertypal/shared/src/hooks/useLocalStorage';
import useMortgageCalculator from '@propertypal/shared/src/hooks/useMortgageCalculator';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import { BLACK } from '@propertypal/shared/src/styles/theme';
import { Development } from '@propertypal/shared/src/types/development';
import { MarketTrendsResponse } from '@propertypal/shared/src/types/marketTrends';
import { KeyInfo, Property } from '@propertypal/shared/src/types/property';
import { SoldPriceData } from '@propertypal/shared/src/types/soldPriceData';
import { getGalleryImages } from '@propertypal/shared/src/utils/property';
import { GalleryItem } from '@propertypal/shared/src/utils/property/getGalleryImages';
import { shouldDisplaySchoolData } from '@propertypal/shared/src/utils/schools';
import { getCategoryAdName } from '@propertypal/shared/src/utils/search/category';
import Accordion, { AccordionHeader } from '@propertypal/web-ui/src/animation/Accordion';
import BroadbandTable from '@propertypal/web-ui/src/broadband/BroadbandTable';
import Button from '@propertypal/web-ui/src/buttons/Button';
import ReportProblemButton from '@propertypal/web-ui/src/buttons/ReportProblemButton';
import PageWrapper from '@propertypal/web-ui/src/layout/PageWrapper';
import useMediaQuery from '@propertypal/web-ui/src/media-query/useMediaQuery';
import ImageModal from '@propertypal/web-ui/src/modal/ImageModal';
import AgentAccreditations from '@propertypal/web-ui/src/property/AgentAccreditations';
import ContactTable from '@propertypal/web-ui/src/property/ContactTable';
import DevelopmentHeader from '@propertypal/web-ui/src/property/DevelopmentHeader';
import KeyInfoList from '@propertypal/web-ui/src/property/KeyInfoList';
import OtherDevelopments from '@propertypal/web-ui/src/property/OtherDevelopments';
import PromoteProperty from '@propertypal/web-ui/src/property/PromoteProperty';
import PropertyAgentBar from '@propertypal/web-ui/src/property/PropertyAgentBar';
import PropertyBanner from '@propertypal/web-ui/src/property/PropertyBanner';
import PropertyDescription from '@propertypal/web-ui/src/property/PropertyDescription';
import PropertySlider from '@propertypal/web-ui/src/property/PropertySlider';
import PropertySummary from '@propertypal/web-ui/src/property/PropertySummary';
import RelatedLinks from '@propertypal/web-ui/src/property/RelatedLinks';
import ShowHomesBar from '@propertypal/web-ui/src/property/ShowHomesBar';
import SimilarProperties from '@propertypal/web-ui/src/property/SimilarProperties';
import SlideshowLinks from '@propertypal/web-ui/src/property/SlideshowLinks';
import Breadcrumbs from '@propertypal/web-ui/src/search/Breadcrumbs';
import { Bold, SubHeading, Text } from '@propertypal/web-ui/src/typography';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { NextRouter, useRouter } from 'next/router';
import React, { FunctionComponent, useState } from 'react';
import { useTheme } from 'styled-components';
import IMAGE_LOCATOR from '../../constants/imageLocator';
import usePropertyFavourite from '../../hooks/usePropertyFavourite';
import useCallAgent from '../../utils/useCallAgent';
import AdBox from '../ads/AdBox';
import generateMetaTags from '../layout/MetaTags';
import PropertyPdfBar from './PropertyPdfBar';
import PropertySocialBar from './PropertySocialBar';
import PropertyUnits from './PropertyUnits';

interface Props {
  property: Property;
  loggedIn: boolean;
  areaPriceTrackerV2: boolean;
}

const AreaPriceTracker = dynamic(() => import('@propertypal/web-ui/src/property/AreaPriceTracker'), { ssr: false });
const BrokerCalculator = dynamic(() => import('@propertypal/web-ui/src/property/BrokerCalculator'), { ssr: false });
const BrochureModal = dynamic(() => import('./BrochureModal'), { ssr: false });
const PropertyStats = dynamic(() => import('@propertypal/web-ui/src/property/PropertyStats'), { ssr: false });
const SchoolsData = dynamic(() => import('../schools/SchoolsData'), { ssr: false });
const EnquiryModal = dynamic(() => import('./EnquiryModal'), { ssr: false });
const GoogleMap = dynamic(() => import('../map/GoogleMap'), { ssr: false });
const PropertyMapModal = dynamic(() => import('./PropertyMapModal'), { ssr: false });
const PropertySlideshowModal = dynamic(() => import('./PropertySlideshowModal'), { ssr: false });
const ReportProblemModal = dynamic(() => import('./ReportProblemModal'), { ssr: false });
const ShareModal = dynamic(() => import('./ShareModal'), { ssr: false });
const StampDutyModal = dynamic(() => import('./StampDutyModal'), { ssr: false });
const MarketTrends = dynamic(() => import('@propertypal/web-ui/src/property/MarketTrends'), { ssr: false });

export const PROPERTY_IMAGE_LOCATOR = ['hd', '580x425:FILL_CROP', '2356x1666:AUTO'];

const AD_BOX_1_SIZES = [[320, 50], 'fluid'];
const AD_BOX_1_STYLE = { backgroundColor: BLACK, padding: '10px 0' };
const AD_BOX_2_SIZES = [[728, 90], 'fluid'];
const AD_BOX_3_SIZES = [[320, 100], [300, 250], [336, 280], 'fluid'];

const getPhotoId = (url: string) => {
  const result = /photo-\d+/.exec(url);

  if (result && result[0]) {
    return parseInt(result[0].replace('photo-', ''), 10) - 1;
  }

  return 0;
};

const routerUpdate = (router: NextRouter, nextUrl: string) => {
  return router.replace(nextUrl, undefined, {
    shallow: true,
  });
};

const PropertyDetails: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const router = useRouter();
  const isMobile = useMediaQuery({ query: `(${laptopMediumMax})` });
  // ignore query-strings when getting url parts
  const urlParts = router.asPath.split('?')[0].split('/');
  const [buyingStatus, setBuyingStatus] = useLocalStorage<string>('buyingStatus', '');
  const { callAgent, modal, showPhoneModal } = useCallAgent();
  const [openSections, setOpenSections] = useState<string[]>([]);
  const [favourited, setFavourited] = useState<boolean>(props.property.shortlisted || false);
  const [modalImage, setModalImage] = useState<string>('');
  const [leadId, setLeadId] = useState(0);
  const imageIndex = getPhotoId(router.asPath);
  const [enquiryAccountNumber, setEnquiryAccountNumber] = useState('');
  const showMapModal = urlParts.includes('map');
  const showShareModal = urlParts.includes('share');
  const showEnquiryModal = urlParts.includes('enquiry');
  const showRegisterInterestModal = props.property.registerInterest && urlParts.includes('register-interest');
  const showRequestValuationModal = props.property.requestValuation && urlParts.includes('request-valuation');
  const showReportModal = urlParts.includes('report-a-problem');
  const showStampDutyModal = urlParts.includes('stamp-duty');
  const showBrochureModal = urlParts.includes('brochure');
  const showSlideshowModal = !!urlParts.find((part) =>
    /^(slideshow|gallery|video|sitemap|floor-plans.*|tour\d+)$/.test(part),
  );
  const isFeaturedDevelopment = !!props.property.featuredDevelopmentContent;
  // Only featured development/units can have sitemap displayed
  const hasSitemap = !!props.property.images?.find((i) => i.imageType === 'SiteMap');
  const hasFeaturedBrochure =
    (props.property.development || props.property.type === 'development') &&
    isFeaturedDevelopment &&
    !!props.property.brochures?.length;
  const showSchoolData = shouldDisplaySchoolData(props.property);
  const images = getGalleryImages(props.property, PROPERTY_IMAGE_LOCATOR[0], true, true);
  const adSuffix = getCategoryAdName(props.property.propertyType?.key, props.property.saleType.key);
  const [soldPriceData, soldPriceError, soldPriceLoading, fetchSoldPriceData] = useApi<SoldPriceData>(
    `/area-price-tracker/property/${props.property.id}`,
    false,
  );
  const [marketTrendsData, marketTrendsError, marketTrendsLoading, fetchMarketTrendsData] =
    useApi<MarketTrendsResponse>(`/market-trends/property/${props.property.id}`, false);
  const similarUrl = `/property/${props.property.id}/${
    props.property.development ? 'other-types' : 'similar'
  }?pageSize=3&imageLocator=${encodeURIComponent(IMAGE_LOCATOR)}`;
  const [similarProperties, , , fetchSimilarProperties] = useApi<Property[]>(similarUrl, false);
  const [otherDevelopments, , , fetchOtherDevelopments] = useApi<Development[]>(
    `/development/${props.property.id}/other-developments?imageLocator=${encodeURIComponent(IMAGE_LOCATOR)}`,
    false,
  );
  const mortgageCalculator = useMortgageCalculator(props.property);
  const showPromoteText = !!(
    props.property.type === 'property' &&
    !props.property.development &&
    props.property.countryCode === 'GBR' &&
    props.property.published &&
    // also should not show when property is sold/let
    props.property.status &&
    !['saleAgreed', 'sold', 'let', 'letAgreed'].includes(props.property.status?.key)
  );
  const onFavourite = usePropertyFavourite(props.loggedIn);

  const isAdObscuringModalOpen = () => {
    return (
      showMapModal ||
      showShareModal ||
      showEnquiryModal ||
      showReportModal ||
      showBrochureModal ||
      showSlideshowModal ||
      showStampDutyModal ||
      showRegisterInterestModal ||
      showRequestValuationModal
    );
  };

  const getReplaceUrl = (nextSection: string, mIndex?: number) => {
    const [currentUrl, qs] = router.asPath.split('?');
    const photoIndex: string | undefined =
      mIndex !== undefined ? (mIndex + 1).toString() : currentUrl.split('/photo-')[1];
    const photo = photoIndex && photoIndex !== '0' ? `/photo-${photoIndex}` : '';

    return `${props.property.path}${nextSection}${photo}${qs ? `?${qs}` : ''}`;
  };

  const updateSectionUrl = (nextSection: string, mIndex?: number) => {
    const nextUrl = getReplaceUrl(nextSection, mIndex);

    routerUpdate(router, nextUrl);
  };

  const updatePhotoUrl = (index: number) => {
    const currentIndex = router.asPath.split('?')[0].split('photo-')[1] || '1';

    if (index !== parseInt(currentIndex, 10) - 1) {
      const url = router.asPath.replace(/\/photo-\d+.*/, '');
      const [currentUrl, qs] = url.split('?');
      const photo = index > 0 ? `/photo-${index + 1}` : '';

      router.replace(`${currentUrl}${photo}${qs ? `?${qs}` : ''}`, undefined, {
        shallow: true,
      });
    }
  };

  const toggleFavourited = async () => {
    const loggedIn = await onFavourite()(!favourited, props.property.id);

    if (loggedIn) setFavourited(!favourited);

    return loggedIn;
  };

  const handleBack = () => {
    updateSectionUrl('');
  };

  const handleImageClick = (image: GalleryItem) => {
    if (image.type === 'webview' && image.insecure) {
      window.open(image.link, image.text, 'popup');
    } else if (image.type === 'webview') {
      updateSectionUrl(`/tour${image.tourIndex}`);
    } else if (image.type === 'video') {
      updateSectionUrl('/video');
    } else if (image.type === 'sitemap') {
      updateSectionUrl('/sitemap');
    } else {
      updateSectionUrl('/slideshow');
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url: props.property.shareURL,
          text: `Found this on PropertyPal`,
        });
      } catch {
        // hide native share errors. Commonly happens if share button is pressed twice in quick succession
      }
    } else {
      updateSectionUrl('/share');
    }
  };

  const handleInfoClick = (keyInfo: KeyInfo) => {
    if (keyInfo.image) {
      setModalImage(keyInfo.image);
    }

    if (keyInfo.key === 'EPC_FILES' && keyInfo.link) {
      window.open(keyInfo.link);
    }

    if (keyInfo.key === 'STAMP_DUTY_COMPLEX') {
      trackGaEvent('key_info_stamp_duty_change', { page: 'PDP', location: 'Key Information' });

      router.replace(`${props.property.path}/stamp-duty`, undefined, { shallow: true });
    }

    if (keyInfo.key === 'TYPICAL_MORTGAGE') {
      trackGaEvent('key_info_mortgage_click', { page: 'PDP', location: 'Key Information' });

      if (isMobile && !openSections.includes('mortgageCalculator')) {
        setOpenSections([...openSections, 'mortgageCalculator']);
      }

      const mortgageCalc = document.getElementById('calculator-marker');
      mortgageCalc?.scrollIntoView({ behavior: 'smooth' });
    }

    if (keyInfo.key === 'BROADBAND') {
      trackGaEvent('key_info_broadband_click', { page: 'PDP', location: 'Key Information' });
      setOpenSections(['broadbandTable']);
      const broadbandTable = document.getElementById('broadband-table');
      broadbandTable?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderAccordionHeader = (key: string, text: string) => {
    if (!isMobile) return undefined;

    const toggleOpen = () => {
      if (openSections.includes(key)) {
        const updatedSections = openSections.filter((section) => section !== key);
        setOpenSections(updatedSections);
      } else {
        setOpenSections([...openSections, key]);
      }
    };
    return <AccordionHeader active={openSections.includes(key)} text={text} onClick={toggleOpen} testID={key} />;
  };

  useEventListener('beforeprint', () => {
    if (props.property.type === 'property') {
      window.open(`/property-print?property=${props.property.id}`);
    }
  });

  return (
    <>
      <Head>
        {generateMetaTags({
          title: props.property.metaDescriptions.title,
          shortLink: props.property.shareURL,
          url: props.property.path,
          description: props.property.metaDescriptions.description,
          accountNumber: props.property.account.accountNumber,
          type: 'place',
          image: props.property.images?.length
            ? {
                url: props.property.images[0].url,
                width: props.property.images[0].width,
                height: props.property.images[0].height,
              }
            : undefined,
          updatedTime: props.property.listingUpdatedTime,
          latLng: props.property.coordinate,
        })}
      </Head>

      {!props.property.featuredDevelopmentContent && (
        <AdBox
          id={`PDPtop/PDPtop_${adSuffix}`}
          config="PDPtop"
          sizes={AD_BOX_1_SIZES}
          hideOver={780}
          obscured={isAdObscuringModalOpen()}
          containerStyle={AD_BOX_1_STYLE}
          recoveryUnitCode="recovery_PDPtop"
          refresh
          timedRefresh
        />
      )}

      <div className="pp-details-page">
        <PropertyAgentBar property={props.property} hideOnMobile />

        {props.property.showHomeOpeningTime && <ShowHomesBar property={props.property} />}

        {props.property.featuredDevelopmentContent?.contentTop && (
          <DevelopmentHeader
            html={props.property.featuredDevelopmentContent?.contentTop}
            property={props.property}
            images={images}
            onOpenSlideshowClick={() => updateSectionUrl('/slideshow', 0)}
            onImageClick={(index) => updateSectionUrl('/slideshow', index)}
          />
        )}

        {!props.property.featuredDevelopmentContent?.contentTop &&
          props.property.coordinate &&
          !urlParts.includes('map') &&
          images.length === 0 && (
            <GoogleMap
              width="100%"
              height={300}
              lat={props.property.coordinate.latitude}
              lng={props.property.coordinate.longitude}
              zoom={props.property.coordinate.mapZoomLevel}
              markers={[
                {
                  id: 1,
                  lat: props.property.coordinate.latitude,
                  lng: props.property.coordinate.longitude,
                },
              ]}
            />
          )}

        {!props.property.featuredDevelopmentContent?.contentTop && images.length > 0 && (
          <PropertySlider
            alt={props.property.addressLine1}
            images={images}
            imageIndex={imageIndex}
            setImageIndex={updatePhotoUrl}
            onClick={handleImageClick}
            onCounterClick={handleImageClick}
            setHeight={(windowWidth, windowHeight, imageHeight) => {
              if (windowWidth <= 650) return Math.min(imageHeight, 400);
              if (windowWidth <= 1024) return Math.min(windowHeight * 0.6, imageHeight);

              return Math.min(windowHeight * 0.75, imageHeight);
            }}
            centerImageCounter
            renderImageOverlay={() => <PropertyBanner property={props.property} disableFeatured />}
            enableKeyboardControls={!showSlideshowModal}
            updateOnIndexChange={!showSlideshowModal}
          />
        )}

        <div className="pp-top-section">
          <SlideshowLinks
            getReplaceUrl={getReplaceUrl}
            property={props.property}
            hasSitemap={hasSitemap}
            hasFeaturedBrochure={hasFeaturedBrochure}
          />

          <Breadcrumbs crumbs={props.property.breadcrumbs} />

          <PageWrapper className="pp-summary-box">
            <PropertySummary
              property={props.property}
              onCallClick={(location) =>
                callAgent(
                  props.property,
                  undefined,
                  undefined,
                  props.property.type === 'development' ? 'DDP' : 'PDP',
                  location,
                )
              }
              onShowNumberClick={(location) => {
                showPhoneModal(
                  props.property,
                  undefined,
                  undefined,
                  props.property.type === 'development' ? 'DDP' : 'PDP',
                  location,
                );
              }}
              onEnquiryClick={() => updateSectionUrl('/enquiry')}
              onRegisterInterestClick={() => updateSectionUrl('/register-interest')}
              onRequestValuationClick={() => updateSectionUrl('/request-valuation')}
              registerInterest={props.property.registerInterest}
              requestValuation={props.property.requestValuation}
              favourited={favourited}
              toggleFavourited={toggleFavourited}
              leadId={leadId}
            />

            {props.property.keyInfo && (
              <KeyInfoList
                property={props.property}
                onClick={handleInfoClick}
                buyingStatus={buyingStatus}
                mortgageCalculator={mortgageCalculator}
              />
            )}

            {props.property.units && (
              <PropertyUnits
                property={props.property}
                hasSitemap={hasSitemap}
                onSiteMapClick={() => updateSectionUrl('/sitemap')}
              />
            )}
          </PageWrapper>

          {!props.property.featuredDevelopmentContent && (
            <AdBox
              id={`PDPmiddle_desktop/PDPmiddle_desktop_${adSuffix}`}
              config="PDPmiddle_desktop"
              sizes={AD_BOX_2_SIZES}
              hideUnder={779}
              obscured={isAdObscuringModalOpen()}
              containerStyle={{
                marginTop: 25,
                marginBottom: 45,
                paddingTop: 35,
                paddingBottom: 35,
                background: '#f5f5f5',
              }}
              declaration
              recoveryUnitCode="recovery_PDPmiddle"
            />
          )}

          {!props.property.featuredDevelopmentContent && (
            <AdBox
              id={`PDPmiddle_mobile/PDPmiddle_mobile_${adSuffix}`}
              config="PDPmiddle_mobile"
              sizes={AD_BOX_3_SIZES}
              hideOver={780}
              obscured={isAdObscuringModalOpen()}
              containerStyle={{
                marginTop: 25,
                marginBottom: 45,
                paddingTop: 35,
                paddingBottom: 35,
                background: '#f5f5f5',
              }}
              declaration
              recoveryUnitCode="recovery_PDPmiddle"
            />
          )}

          {props.property.properties && props.property.published && (
            <SimilarProperties
              properties={props.property.properties}
              imageLocator={PROPERTY_IMAGE_LOCATOR[1]}
              onFavourite={onFavourite}
              title="Property Types"
              subTitle={`(${props.property.totalAvailableTypes} available)`}
              wrapOnDesktop
            />
          )}

          {props.property.type === 'property' && (
            <PropertySocialBar
              property={props.property}
              favourited={favourited}
              onFavourite={toggleFavourited}
              onShare={handleShare}
              loggedIn={props.loggedIn}
            />
          )}

          <PageWrapper className="pp-description-box">
            <PropertyDescription
              property={props.property}
              onItemClick={handleImageClick}
              onImageClick={(index) => updateSectionUrl('/slideshow', index)}
              images={images}
            />

            {hasFeaturedBrochure && (
              <Button
                onClick={() => routerUpdate(router, `${props.property.path}/brochure/1`)}
                containerStyle={{ maxWidth: 260, margin: '30px 0' }}
              >
                View Brochure
              </Button>
            )}
          </PageWrapper>

          <PropertyPdfBar
            property={props.property}
            onItemClick={(path) => routerUpdate(router, `${props.property.path}${path}`)}
            isFeaturedDevelopment={isFeaturedDevelopment}
          />
        </div>

        {props.property.type === 'property' && props.property.development && isFeaturedDevelopment && (
          <SimilarProperties
            properties={similarProperties}
            onInView={fetchSimilarProperties}
            title="More from this development"
            background={theme.backgroundLightest}
            hideOnEmpty
            onFavourite={onFavourite}
            wrapOnDesktop
          />
        )}

        {(!!props.property.account?.accreditations?.length || !!props.property.account.missingAccreditations?.length) &&
          props.property.type === 'property' &&
          !props.property.featuredDevelopmentContent && (
            <Accordion
              className="hide-print"
              isOpen={!isMobile || openSections.includes('accreditations')}
              header={renderAccordionHeader('accreditations', 'Agent Accreditations')}
            >
              <PageWrapper>
                {!isMobile && <SubHeading mt={60}>Agent Accreditations</SubHeading>}

                <AgentAccreditations
                  accreditations={props.property.account.accreditations}
                  missingAccreditations={props.property.account.missingAccreditations}
                />
              </PageWrapper>
            </Accordion>
          )}

        {mortgageCalculator.hasMortgageCalculator && (
          <>
            <div id="calculator-marker" />

            <Accordion
              className="hide-print"
              isOpen={!isMobile || openSections.includes('mortgageCalculator')}
              header={renderAccordionHeader('mortgageCalculator', 'Mortgage Calculator')}
            >
              <BrokerCalculator property={props.property} mortgageCalculator={mortgageCalculator} />
            </Accordion>
          </>
        )}

        {/* {shouldDisplayGuides(props.property) && (
          <Accordion
            className="hide-print"
            isOpen={!isMobile || openSections.includes('contentHubGuides')}
            header={renderAccordionHeader('contentHubGuides', 'Home Buying Guides')}
          >
            <ContentHubGuidesPreview />
          </Accordion>
        )} */}

        <div id="broadband-table" />

        {props.property.ofcomBroadband && (
          <Accordion
            className="hide-print"
            isOpen={!isMobile || openSections.includes('broadbandTable')}
            header={renderAccordionHeader('broadbandTable', 'Broadband Checker')}
          >
            <div style={{ width: '100%', backgroundColor: theme.backgroundLightest }}>
              {!isMobile && (
                <PageWrapper>
                  <SubHeading mt={30} data-testid="broadband-checker-title">
                    Broadband Checker
                  </SubHeading>
                </PageWrapper>
              )}

              <BroadbandTable displayAddress={props.property.addressLine1} broadband={props.property.ofcomBroadband} />
            </div>
          </Accordion>
        )}

        {(props.property.type === 'development' || props.property.development) &&
          props.property.contacts &&
          props.property.contacts.all.length > 1 &&
          props.property.published && (
            <Accordion
              className="pp-contact-box hide-print"
              isOpen={!isMobile || openSections.includes('contactTable')}
              header={renderAccordionHeader('contactTable', 'Contact the Agent')}
            >
              <ContactTable
                property={props.property}
                onEnquiryClick={(contact) => {
                  trackGaEvent('enquiry_click', {
                    location: 'Contact Table',
                    account_number: contact.accountNumber,
                    page: 'DDP',
                  });
                  setEnquiryAccountNumber(contact.accountNumber);
                  updateSectionUrl('/enquiry');
                }}
                onRegisterInterestClick={(contact) => {
                  setEnquiryAccountNumber(contact.accountNumber);
                  updateSectionUrl('/register-interest');
                }}
                registerInterest={props.property.registerInterest}
              />
            </Accordion>
          )}

        {showSchoolData && (
          <Accordion
            className="hide-print"
            isOpen={!isMobile || openSections.includes('schoolsData')}
            header={renderAccordionHeader('schoolsData', 'Schools Data')}
          >
            <SchoolsData property={props.property} />
          </Accordion>
        )}

        {props.property.priceTrackerAvailable && props.property.published && !props.areaPriceTrackerV2 && (
          <Accordion
            className="hide-print"
            isOpen={!isMobile || openSections.includes('areaPriceTracker')}
            header={renderAccordionHeader('areaPriceTracker', 'Area Price Tracker')}
          >
            <AreaPriceTracker
              soldPriceData={soldPriceData}
              onInView={fetchSoldPriceData}
              loading={soldPriceLoading}
              error={soldPriceError}
            />
          </Accordion>
        )}

        {/* ensure property is published and is not a development property where we don't show any stats */}
        {props.property.published && props.property.type === 'property' && !props.property.development && (
          <Accordion
            className="hide-print"
            isOpen={!isMobile || openSections.includes('stats')}
            header={renderAccordionHeader('stats', 'Property Statistics')}
          >
            <PropertyStats property={props.property} showPromoteText={showPromoteText} />
          </Accordion>
        )}

        {props.property.priceTrackerAvailable && props.property.published && props.areaPriceTrackerV2 && (
          <Accordion
            className="hide-print"
            isOpen={!isMobile || openSections.includes('marketTrends')}
            header={renderAccordionHeader('marketTrends', 'Northern Ireland House Prices')}
          >
            <MarketTrends
              marketTrendsData={marketTrendsData}
              onInView={fetchMarketTrendsData}
              loading={marketTrendsLoading}
              error={marketTrendsError}
              addressLine1={props.property.addressLine1}
            />
          </Accordion>
        )}

        {props.property.type === 'property' && !props.property.development && (
          <SimilarProperties
            properties={similarProperties}
            onInView={fetchSimilarProperties}
            title="Similar Properties"
            background={theme.backgroundLightest}
            onFavourite={onFavourite}
            emptyText="Sorry, we couldn't find any similar properties to this"
          />
        )}

        {props.property.featuredDevelopmentContent && (
          <div
            className="pp-content-middle"
            dangerouslySetInnerHTML={{ __html: props.property.featuredDevelopmentContent.contentMiddle }}
          />
        )}

        {props.property.type === 'development' && (
          <OtherDevelopments data={otherDevelopments} onInView={fetchOtherDevelopments} />
        )}

        {props.property.featuredDevelopmentContent && (
          <div
            className="pp-content-bottom"
            dangerouslySetInnerHTML={{ __html: props.property.featuredDevelopmentContent.contentBottom }}
          />
        )}

        <PageWrapper className="hide-print">
          <Text align="center" mt={30} mb={showPromoteText ? 0 : 30} className="pp-report-a-problem">
            <Bold>SPOTTED A PROBLEM?</Bold> Even the best agents make mistakes, help us to maintain accurate property
            information on our listings. <ReportProblemButton onClick={() => updateSectionUrl('/report-a-problem')} />
          </Text>
          {showPromoteText && <PromoteProperty property={props.property} label="Small Print" />}
        </PageWrapper>

        <RelatedLinks property={props.property} />

        <ImageModal
          ariaLabel="EPC image popup"
          show={!!modalImage}
          onClose={() => setModalImage('')}
          imageUrl={modalImage}
        />

        {showStampDutyModal && (
          <StampDutyModal
            buyingStatus={buyingStatus}
            setBuyingStatus={setBuyingStatus}
            property={props.property}
            show={showStampDutyModal}
            onClose={handleBack}
          />
        )}

        {showMapModal && (
          <PropertyMapModal show={showMapModal} property={props.property} onClose={handleBack} adSuffix={adSuffix} />
        )}

        {showSlideshowModal && (
          <PropertySlideshowModal
            imageIndex={imageIndex}
            setImageIndex={updatePhotoUrl}
            show={showSlideshowModal}
            property={props.property}
            onClose={handleBack}
            onTabClick={(tab, index) => updateSectionUrl(`/${tab}`, index)}
            url={router.asPath}
            imageLocator={PROPERTY_IMAGE_LOCATOR[0]}
            hasSitemap={hasSitemap}
            adSuffix={adSuffix}
          />
        )}

        {(showEnquiryModal || showRegisterInterestModal || showRequestValuationModal) && (
          <EnquiryModal
            accountNumber={enquiryAccountNumber}
            show={showEnquiryModal || showRegisterInterestModal || showRequestValuationModal}
            onClose={handleBack}
            property={props.property}
            registerInterestModal={showRegisterInterestModal}
            requestValuationModal={showRequestValuationModal}
            onSuccess={setLeadId}
            loggedIn={props.loggedIn}
          />
        )}

        {showBrochureModal && <BrochureModal show={showBrochureModal} onClose={handleBack} property={props.property} />}

        {showReportModal && (
          <ReportProblemModal show={showReportModal} onClose={handleBack} property={props.property} />
        )}

        {showShareModal && (
          <ShareModal show={router.asPath.includes('/share')} onClose={handleBack} property={props.property} />
        )}

        {modal}

        {props.property.featuredDevelopmentContent && (
          <style dangerouslySetInnerHTML={{ __html: props.property.featuredDevelopmentContent.css }} />
        )}
      </div>
    </>
  );
};

export default React.memo(PropertyDetails);
